
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { EsService } from '@/services/es.service';
import { JnService } from '@/services/jn.service';
import JnProgress from '@/views/common/Progress.vue';
import { Picklist } from '@/models/picklist.model';
import { ValidationObserver } from 'vee-validate';
import { SearchData } from '@/models/search.model';
import EventBus from '@/services/event.bus';
import moment from 'moment';
import {
  CategoriesGiurisprudenza,
  CategoriesPrassiJuranet,
  CategoriesNormativa,
  CategoriesJuranet,
  OrderingTypes,
  SearchTypes,
} from '@/models/enums.model';
import JnAutocompleteSearch from '@/views/common/Autocomplete.vue';

@Component({
  components: {
    JnProgress,
    ValidationObserver,
    JnAutocompleteSearch,
  },
})
export default class JnJuranewsSearch extends Vue {
  @Prop({ required: true })
  searchData?: SearchData;

  optText1 = '';
  optText2 = '';
  showText2 = false;
  optText3 = '';
  showText3 = false;
  optType = 'AND';
  optCategorie: Picklist[] = [];
  optSubCategorie: Picklist[] = [];
  optMaterie: Picklist[] = [];
  optDataDa = '';
  optDataDaFormatted = '';
  optDataA = '';
  optDataAFormatted = '';
  optDateRange = false;
  optAnno = new Picklist({ value: '', label: '' });
  optNumero = '';
  optCitta = ''
  optRicorso = ''
  optCausa = ''
  optDataGazzetta = '';
  optDataGazzettaFormatted = '';
  optAnnoGazzetta = new Picklist({ value: '', label: '' });
  optNumeroGazzetta = '';
  optTipoOrdinamento = '1';
  optNumRisultati = '20';
  optSearchMassime = false;
  optSezioneCassazione = new Picklist({ value: '', label: '' });
  optSottoSezioniCassazioneCivile = new Picklist({ value: '', label: '' });
  optSottoSezioniCassazionePenale = new Picklist({ value: '', label: '' });
  optRegione = new Picklist({ value: '', label: '' });

  menuDataDa = false;
  menuDataA = false;
  menuDataGazzetta = false;

  tipiQuery: Picklist[] = [];
  categorie: Picklist[] = [];
  subCategorie: Picklist[] = [];
  categorieRaw!: any;
  materie: Picklist[] = [];
  tipiOrdinamento: Picklist[] = [];
  numRisultati: Picklist[] = [];
  anni: Picklist[] = [];
  sezioniCassazione: Picklist[] = [];
  sottoSezioniCassazioneCivile: Picklist[] = [];
  sottoSezioniCassazionePenale: Picklist[] = [];
  regioni: Picklist[] = [];

  alert = false;
  alertMessage = '';
  alertTimeout = 6000;

  advanced = true;
  loading = false;

  showMaterie = false;
  showSezioneCassazione = false;
  showCitta = false
  showCausa = false
  showRicorso = false

  @Watch('optCategorie')
  changeMateria() {
    if (this.optCategorie.length == 1 && this.optCategorie[0].value == '1') {
      this.showMaterie = true;
    } else {
      this.showMaterie = false;
      this.optDataGazzetta = '';
      this.optNumeroGazzetta = '';
      this.optAnnoGazzetta = new Picklist({ value: '', label: '' });
    }
  }
  @Watch('optSubCategorie')
  changeSubCategorie() {
    if (!this.isNormativaRegionaleSelected()) {
      this.optRegione = new Picklist({ value: '', label: '' });
    }
  }

  @Watch('optCategorie')
  isNormativaSelected() {
    return this.optCategorie.length == 1 && this.optCategorie[0].value == '1';
  }
  @Watch('optCategorie')
  isGiurisprudenzaSelected() {
    let isGiurisprudenzaSelected = false;
    for (const c of this.optCategorie) {
      if (c.value == '2') {
        isGiurisprudenzaSelected = true;
      }
    }
    return isGiurisprudenzaSelected;
  }
  @Watch('optSubCategorie')
  isCassazioneSelected() {
    return this.optSubCategorie && this.optSubCategorie.length == 1 && this.optSubCategorie[0].value.split("|")[1] == CategoriesJuranet.DCCASS;
  }

  @Watch('subCategorie')
  isNormativaRegionaleSelected() {
    return this.optSubCategorie && this.optSubCategorie.length == 1 && this.optSubCategorie[0].value.split("|")[1] == CategoriesJuranet.DLR;
  }

  @Watch('subCategorie')
  isNormativaRegionaleAndOtherSelected() {

    if (this.optSubCategorie && this.optSubCategorie.length > 1) {
      let isNormativaRegionaleAndOtherSelected = false;
      for (const c of this.optSubCategorie) {
        if (c.value.split("|")[1] == CategoriesJuranet.DLR) {
          isNormativaRegionaleAndOtherSelected = true;
        }
      }
      return isNormativaRegionaleAndOtherSelected;
    }
    else {
      return false
    }
  }

  @Watch('subCategorie')
  isGiurisprudenzaCeduSelected() {
    if (this.optSubCategorie && this.optSubCategorie.length == 1) {
      return this.optSubCategorie[0].value.split("|")[1] == CategoriesJuranet.CEDU;
    }
    else {
      return false
    }
  }

  @Watch('optSezioneCassazione')
  isCivileSelected() {
    if (this.optSezioneCassazione != null) {
      return this.optSezioneCassazione.value == 'civile';
    }
    return false;
  }
  @Watch('optSezioneCassazione')
  isPenaleSelected() {
    return this.optSezioneCassazione.value == 'penale';
  }

  getDataGazzettaBurLabel() {
    if (this.isNormativaRegionaleSelected()) {
      return 'Data BUR';
    }
    else if (!this.isNormativaRegionaleSelected() || this.isNormativaRegionaleAndOtherSelected()) {
      return 'Data Gazzetta';
    }
  }
  getAnnoGazzettaBurLabel() {
    if (this.isNormativaRegionaleSelected()) {
      return 'Anno BUR';
    }
    else if (!this.isNormativaRegionaleSelected() || this.isNormativaRegionaleAndOtherSelected()) {
      return 'Anno Gazzetta';
    }
  }
  getNumeroGazzettaBurLabel() {
    if (this.isNormativaRegionaleSelected()) {
      return 'Numero BUR';
    }
    else if (!this.isNormativaRegionaleSelected() || this.isNormativaRegionaleAndOtherSelected()) {
      return 'Numero Gazzetta';
    }
  }

  // Mostra solo se è selezionata "Corti di merito", "Tar" o "Commissioni tributarie"
  @Watch('optSubCategorie')
  activateCitta() {
    let activate = false
    if (this.optSubCategorie.length >= 1) {
      for (const subCategory of this.optSubCategorie) {
        activate = subCategory.value == '2|28' || subCategory.value == '2|40' || subCategory.value == '2|70'
        if (activate) {
          break
        }
      }
    }
    this.showCitta = activate
  }

  // Mostra solo se è selezionata la "Corte di Cassazione"
  @Watch('optSubCategorie')
  activateTipoSentenza() {
    if (
      this.optSubCategorie.length == 1 &&
      this.optSubCategorie[0].value == '2|19'
    ) {
      this.showSezioneCassazione = true;
    } else {
      this.optSezioneCassazione = new Picklist({ value: '', label: '' });
      this.showSezioneCassazione = false;
    }
  }

  // Mostra solo se è selezionata Giurisprudenza e solo CEDU
  @Watch('optSubCategorie')
  activateCausaRicorso() {
    let activate = false
    if (this.optSubCategorie.length == 1) {
      activate = this.optSubCategorie[0].value == "2|84"
    }
    this.showCausa = activate
    this.showRicorso = activate
  }

  async switchAdvanced() {
    this.advanced = !this.advanced;
  }

  parseDate(date: string) {
    return date ? moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
  }

  addTextSearch() {
    if (!this.showText2) {
      this.showText2 = true;
    } else if (!this.showText3) {
      this.showText3 = true;
    }
  }
  removeTextSearch2() {
    this.showText2 = false;
  }
  removeTextSearch3() {
    this.showText3 = false;
  }

  async search() {
    if (this.searchData) {
      const optText1 = this.optText1
        ? this.optText1.replaceAll('/', '\\/')
        : this.optText1;
      const optText2 = this.showText2 && this.optText2
        ? this.optText2.replaceAll('/', '\\/')
        : "";
      const optText3 = this.showText3 && this.optText3
        ? this.optText3.replaceAll('/', '\\/')
        : "";
      this.searchData.keywords = []
      if (optText1 != "") {
        this.searchData.keywords.push(optText1)
      }
      if (this.showText2 && optText2 != "") {
        this.searchData.keywords.push(optText2)
      }
      if (this.showText3 && optText3 != "") {
        this.searchData.keywords.push(optText3)
      }

      this.searchData.queryType = this.optType;
      if (this.optTipoOrdinamento) {
        this.searchData.orderingType = this.optTipoOrdinamento;
      }
      if (this.optNumRisultati) {
        this.searchData.perPage = Number(this.optNumRisultati);
      }
      const idMaterie: string[] = [];

      for (const m of this.optMaterie) {
        idMaterie.push(m.value);
      }
      this.searchData.idMateria = idMaterie;

      if (this.optSezioneCassazione != null) {
        this.searchData.sezioneCassazione = (
          this.optSezioneCassazione as unknown as Picklist
        ).value;

        if (this.isCivileSelected()) {
          this.searchData.sezioneCassazione = this.optSottoSezioniCassazioneCivile.value + " " + this.optSezioneCassazione.value;
        }
        if (this.isPenaleSelected()) {
          this.searchData.sezioneCassazione = this.optSottoSezioniCassazionePenale.value + " " + this.optSezioneCassazione.value;
        }
      }

      this.searchData.year = this.optAnno?.value;
      if (this.optNumero) {
        this.searchData.number = this.optNumero;
      } else {
        this.searchData.number = '';
      }


      let datiGazzettaSelected = false
      if (this.optAnnoGazzetta && this.optAnnoGazzetta?.value && this.optAnnoGazzetta?.value != '') {
        datiGazzettaSelected = true
        this.searchData.annoGazzetta = this.optAnnoGazzetta?.value;
      }
      else {
        this.searchData.annoGazzetta = '';
      }
      if (this.optDataGazzetta === null) {
        this.optDataGazzetta = '';
      }
      if (this.optDataGazzetta != '') {
        datiGazzettaSelected = true
        try {
          this.optDataGazzetta = moment(this.optDataGazzettaFormatted, 'DD-MM-YYYY').format(
            'YYYY-MM-DD'
          );
        } catch (err) {
          console.error(err);
        }
      }
      if (this.optNumeroGazzetta) {
        datiGazzettaSelected = true
        this.searchData.numeroGazzetta = this.optNumeroGazzetta;
      } else {
        this.searchData.numeroGazzetta = '';
      }
      if (this.optRegione && this.optRegione.value != '') {
        this.searchData.regione = this.optRegione.value;
      } else {
        this.searchData.regione = '';
      }
      if (this.optCausa && this.optCausa != '') {
        this.searchData.causa = this.optCausa;
      }
      else {
        this.searchData.causa = undefined;
      }
      if (this.optRicorso && this.optRicorso != '') {
        this.searchData.ricorso = this.optRicorso;
      }
      else {
        this.searchData.ricorso = undefined;
      }

      const idCategorie: string[] = [];
      for (const c of this.optSubCategorie) {
        const idCategory = c.value.split('|')[1]
        // Se è selezionata la normativa regionale e almeno uno dei dati della gazzetta, togliere la normativa
        // regionale tra le categorie da cercare. Questo perchè erroneamente il campo "dataGazzetta" sull'indice
        // ora contiene in realtà la Data BUR
        if (this.isNormativaRegionaleAndOtherSelected() && datiGazzettaSelected) {
          if (idCategory != CategoriesJuranet.DLR) {
            idCategorie.push(idCategory);
          }
        }
        else {
          idCategorie.push(idCategory);
        }
      }
      this.searchData.idCategory = idCategorie;

      console.log(this.searchData.idCategory)

      if (this.optDataDaFormatted === null) {
        this.optDataDaFormatted = '';
      }
      if (this.optDataAFormatted === null) {
        this.optDataAFormatted = '';
      }
      if (this.optDataDaFormatted) {
        try {
          this.optDataDa = moment(this.optDataDaFormatted, 'DD-MM-YYYY').format(
            'YYYY-MM-DD'
          );
        } catch (err) {
          console.log(err);
        }
      }
      if (this.optDataAFormatted) {
        try {
          this.optDataA = moment(this.optDataAFormatted, 'DD-MM-YYYY').format(
            'YYYY-MM-DD'
          );
        } catch (err) {
          console.log(err);
        }
      }
      if (this.optDateRange) {
        if (this.optDataDa && !this.optDataA) {
          this.alert = true;
          this.alertMessage = 'Data A mancante';
          return;
        } else if (!this.optDataDa && this.optDataA) {
          this.alert = true;
          this.alertMessage = 'Data Da mancante';
          return;
        }
      }

      this.searchData.dataGazzetta = this.optDataGazzetta;
      this.searchData.since = this.optDataDa;
      this.searchData.to = this.optDataA;

      if (this.optNumero) {
        this.searchData.number = this.optNumero;
      }
      else {
        this.searchData.number = undefined
      }
      if (this.optCitta) {
        this.searchData.citta = this.optCitta;
      }
      else {
        this.searchData.citta = undefined
      }

      this.searchData.searchInMassime = this.optSearchMassime;
      if (this.optSearchMassime) {
        if (
          this.searchData.keywords.length == 0 ||
          this.searchData.keywords[0].trim() == ''
        ) {
          this.alertMessage =
            'Per ricercare nelle massime della Cassazione è necessario indicare almeno una chiave di ricerca';
          this.alert = true;
          return;
        }
        this.searchData.searchInMassime = this.optSearchMassime;
        this.searchData.idCategory = [CategoriesJuranet.DCCASS];
      }
      this.loading = true;

      const docs = await EsService.getDocs(this.searchData);

      if (docs.length == 0) {
        this.alert = true;
        this.alertMessage = 'Nessun risultato trovato';
      }
      this.loading = false;

      console.log("SEARCH 1")
      console.log(this.searchData)
      console.log("SEARCH 2")
      EventBus.$emit('search-data', this.searchData);
      EventBus.$emit('search-results', docs);

      this.$store.commit('searchData', this.searchData);
    }
  }

  saveDataDa(date: any) {
    this.optDataDaFormatted = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    this.menuDataDa = false;
    this.optAnno = new Picklist({ value: '', label: '' });
  }
  saveDataA(date: any) {
    this.optDataAFormatted = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    this.menuDataA = false;
    this.optAnno = new Picklist({ value: '', label: '' });
  }
  saveDataGazzetta(date: any) {
    this.optDataGazzettaFormatted = moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
    this.menuDataGazzetta = false;
  }
  resetRange() {
    this.optDataDa = '';
    this.optDataA = '';
    this.optDataDaFormatted = '';
    this.optDataAFormatted = '';
  }

  changeCategoria() {
    this.optSubCategorie = [];
    this.subCategorie = [];
    for (const selectedCategoria of this.optCategorie) {
      for (const sc of this.categorieRaw) {
        if (sc.id.includes(selectedCategoria.value + '|')) {
          this.subCategorie.push(
            new Picklist({ value: sc.id, label: sc.value })
          );
        }
      }
    }
  }

  clear() {
    EventBus.$emit('clear-keywords');
    this.optText1 = '';
    this.optText2 = '';
    this.showText2 = false;
    this.optText3 = '';
    this.showText3 = false;
    this.optType = 'AND';
    this.optCategorie = [];
    this.optSubCategorie = [];
    this.optMaterie = [];
    this.optDataDa = '';
    this.optDataDaFormatted = '';
    this.optDataA = '';
    this.optDataAFormatted = '';
    this.optDataGazzetta = '';
    this.optDataGazzetta = '';
    this.optAnno = new Picklist({ value: '', label: '' });
    this.optNumero = '';
    this.optTipoOrdinamento = OrderingTypes.DATA_CREAZIONE;
    this.optNumRisultati = this.numRisultati[0].value;
    this.optSearchMassime = false;
    this.optRegione = new Picklist({ value: '', label: '' });
  }

  close() {
    this.clear();
    this.advanced = false;
  }

  async created() {
    EventBus.$on('reset-ricerca-banca-dati', () => {
      this.search();
    });
    EventBus.$on('search-data', async (searchData: SearchData) => {
      if (searchData) {
        const newSearchData = searchData.clone();
        if (
          newSearchData &&
          newSearchData.searchScope &&
          newSearchData.searchScope == 'banca-dati'
        ) {
          if (!newSearchData!.globalSearch) {
            if (newSearchData.idCategory!.length == 0) {
              this.normativaResultCount(newSearchData);
              this.giurisprudenzaResultCount(newSearchData);
              this.prassiResultCount(newSearchData);
              this.showSearchBancaDati = true;
            } else {
              this.showSearchBancaDati = false;
            }
          }
        }
      }
    });
    EventBus.$on('select-text', (suggestion: any) => {
      if (suggestion.searchScope == SearchTypes.ALL) {
        if (suggestion.index == 0) {
          this.optText1 = suggestion.suggestion;
        } else if (suggestion.index == 1) {
          this.optText2 = suggestion.suggestion;
        } else if (suggestion.index == 2) {
          this.optText3 = suggestion.suggestion;
        }
      }
    });
    this.tipiQuery = JnService.getQueryTypes();
    this.tipiOrdinamento = JnService.getTipiOrdinamento();
    this.sezioniCassazione = JnService.getSezioniCassazione();
    this.sottoSezioniCassazioneCivile = JnService.getSottoSezioniCassazioneCivile();
    this.sottoSezioniCassazionePenale = JnService.getSottoSezioniCassazionePenale();
    this.materie = await EsService.getPicklist('materie-banca-dati-juranet');
    this.categorieRaw = JSON.parse(
      await EsService.getOptions('categorie-banca-dati-juranet')
    );
    for (const c of this.categorieRaw) {
      if (!c.id.includes('|')) {
        this.categorie.push(new Picklist({ value: c.id, label: c.value }));
      }
    }
    this.optTipoOrdinamento = this.tipiOrdinamento[1].value;
    this.numRisultati = JnService.getNumeroRisultati();
    this.optNumRisultati = this.numRisultati[0].value;
    this.anni = JnService.getAnni();
    this.regioni = JnService.getRegioni();
  }

  /**
   * Partial Search
   */
  showSearchBancaDati = false;
  totalResultsNormativa = 0;
  totalResultsGiurisprudenza = 0;
  totalResultsPrassiDecisioni = 0;
  searchNormativaSelected = false;
  searchGiurisprudenzaSelected = false;
  searchPrassiDecisioniSelected = false;

  async normativaResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesNormativa;
    7;
    console.log(
      'JuranetSearch.normativaResultCount(searchData): ' +
      JSON.stringify(searchData, null, 2)
    );
    this.totalResultsNormativa = await EsService.countDocs(searchData!);
    console.log(
      'JuranetSearch.normativaResultCount(count): ' + this.totalResultsNormativa
    );
  }

  async searchNormativa() {
    this.loading = true;
    const searchData = this.searchData!.clone();
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesNormativa;
    searchData!.globalSearch = true;
    searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(searchData!);
    if (docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', searchData);
      EventBus.$emit('search-results', docs);
    }
    console.log('JuranetSearch.searchNormativa(total-results): ' + docs.length);
    this.searchNormativaSelected = true;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = false;
    this.loading = false;
  }

  async giurisprudenzaResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesGiurisprudenza;
    this.totalResultsGiurisprudenza = await EsService.countDocs(searchData!);
  }
  async searchGiurisprudenza() {
    this.loading = true;
    const searchData = this.searchData!.clone();
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesGiurisprudenza;
    searchData!.globalSearch = true;
    searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(searchData!);
    if (docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = true;
    this.searchPrassiDecisioniSelected = false;
    this.loading = false;
  }

  async prassiResultCount(searchData: SearchData) {
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesPrassiJuranet;
    console.log(
      'JuranetSearch.prassiResultCount(searchData): ' +
      JSON.stringify(searchData, null, 2)
    );
    this.totalResultsPrassiDecisioni = await EsService.countDocs(searchData!);
    console.log(
      'JuranetSearch.prassiResultCount(count): ' +
      this.totalResultsPrassiDecisioni
    );
  }
  async searchPrassiDecisioni() {
    this.loading = true;
    const searchData = this.searchData!.clone();
    searchData!.index = process.env.VUE_APP_JURANET_INDEXES!;
    searchData!.idCategory = CategoriesPrassiJuranet;
    searchData!.globalSearch = true;
    searchData!.orderingType = this.tipiOrdinamento[1].value;
    const docs = await EsService.getDocs(searchData!);
    if (docs.length == 0) {
      EventBus.$emit('search-results', []);
    } else {
      EventBus.$emit('search-data', searchData);
      EventBus.$emit('search-results', docs);
    }
    this.searchNormativaSelected = false;
    this.searchGiurisprudenzaSelected = false;
    this.searchPrassiDecisioniSelected = true;
    this.loading = false;
  }

  resetForMassime() {
    this.optCategorie = [];
    this.optSubCategorie = [];
    this.optMaterie = [];
  }
}
